<template lang="pug">
template(v-if='updateRoute')
  NuxtLink(:to='routeObj')
    slot

template(v-else)
  button(@click='updatePage')
    slot
</template>

<script>
import SearchComponent from './Component'

export default defineNuxtComponent({
  extends: SearchComponent,
  props: {
    pageNumber: {
      type: Number,
      required: true,
    },
    updateRoute: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    routeObj() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { page, ...restOfQuery } = this.$route.query

      return {
        name: this.$route.name,
        params: {
          ...this.$route.params,
          // noUpdateScroll: true,
        },
        query: {
          ...restOfQuery,
          ...(this.pageNumber > 1 && { page: this.pageNumber }),
        },
      }
    },
  },
  methods: {
    updatePage() {
      // Update the page without impacting the URL
      this.$searchContext.setPage(this.pageNumber)
      this.$searchContext.search()
    },
  },
})
</script>
