<template lang="pug">
div(v-if='totalPages > 1')
  ul(class='flex items-center justify-center md:justify-end')
    li(v-if='currentPage > 1' class='mr-1')
      AlgoliaPaginationLink(
        :page-number='currentPage - 1'
        :update-route='updateRoute'
        class='flex justify-center items-center text px-3 w-10 h-10 bg-white border rounded md:w-auto'
        aria-label='Previous Page'
      )
        img(src='@/assets/arrow.svg' class='w-3 transform rotate-90' height='12' width='12' alt='Previous Page')
        span(class='hidden lg:block ml-2') Prev

    template(v-for='item in items')
      template(v-if='item === "ellipsis"')
        li(:key='item')
          div(class='flex justify-center items-end')
            | &hellip;

      template(v-else)
        li(:key='item' class='mx-1 md:last:mr-0')
          AlgoliaPaginationLink(
            :page-number='item'
            :update-route='updateRoute'
            class='flex justify-center items-center text p-2 rounded md:w-10 md:h-10 md:hover:border md:border-gray-dark md:hover:no-underline'
            :class='{ "or-pagination-active font-bold md:bg-primary": item === currentPage }'
          )
            | {{ item }}

    li(v-if='currentPage < totalPages' class='ml-1')
      AlgoliaPaginationLink(
        :page-number='currentPage + 1'
        :update-route='updateRoute'
        class='flex justify-center items-center text px-3 w-10 h-10 bg-white border rounded md:w-auto'
        aria-label='Next Page'
      )
        span(class='hidden lg:block mr-2') Next
        img(src='@/assets/arrow.svg' class='w-3 transform -rotate-90' height='12' width='12' alt='Next Page')
</template>

<script>
import SearchComponent from './Component'

export default defineNuxtComponent({
  extends: SearchComponent,
  props: {
    updateRoute: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentPage() {
      return this.$searchContext.state.value.currentPage
    },
    totalPages() {
      return this.$searchContext.state.value.totalPages
    },
    rangeStart() {
      let startPage = 2

      if (this.totalPages > 7 && this.currentPage > 4) {
        if (this.currentPage > this.totalPages - 4) startPage = this.totalPages - 4
        else startPage = this.currentPage - 1
      }

      return startPage
    },
    rangeEnd() {
      let endPage = this.totalPages - 1

      if (this.totalPages > 7) {
        if (this.currentPage < 5) endPage = 5
        else if (this.currentPage < this.totalPages - 3) endPage = this.currentPage + 1
      }

      return endPage
    },
    items() {
      const range = []

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) range.push(i)

      return [
        1,
        ...(this.rangeStart > 2 ? ['ellipsis'] : []),
        ...range,
        ...(this.totalPages - this.rangeEnd > 1 ? ['ellipsis'] : []),
        this.totalPages,
      ]
    },
    limitedPages() {
      const pages = []
      let startPage = 2
      let endPage = this.totalPages - 1

      if (this.totalPages > 8) {
        if (this.currentPage < 5) endPage = 5
        else if (this.currentPage > this.totalPages - 4) startPage = this.totalPages - 4
        else {
          startPage = this.currentPage - 1
          endPage = this.currentPage + 1
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i)
      }

      return pages
    },
  },
})
</script>
